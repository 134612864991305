<script setup>
import {computed, ref} from "vue";
import {ThemeStore} from "@/Stores/index.js";
import {ArrowRightStartOnRectangleIcon, MoonIcon, SunIcon} from "@heroicons/vue/24/outline";
import {PageTransition} from "@/Components/Transitions/index.js";
import {Link, usePage} from "@inertiajs/vue3";
import {UserInfo} from "@/Components/Common/index.js";
import {UserCircleIcon} from "@heroicons/vue/24/solid/index.js";

defineProps({
    layoutSize: {
        type: String,
        default: 'normal',
    },
})
const page = usePage();

const isDarkMode = computed(() => {
    return ThemeStore.isDark()
});

const isDropdownOpen = ref(false);

const dropdownItems = ref([
    {
        label: 'Sign Out',
        route: route('logout'),
        method: 'post',
        icon: ArrowRightStartOnRectangleIcon,
    },
]);

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};

</script>
<template>
    <div class="bg-purple-50 dark:bg-slate-900 min-h-screen">
        <div class="p-2 sm:p-5 lg:p-10">
            <div
                :class="{
                    'max-w-[1000px]': layoutSize === 'normal',
                    '': layoutSize === 'wide',
                }"
                class="relative bg-white dark:bg-slate-800 rounded-xl mx-auto p-2 sm:p-5 lg:p-10 border border-purple-600 dark:border-slate-700">
                <a href="/">
                    <img :src="isDarkMode ? '/images/logo-large-dark.svg' : '/images/logo-large.svg'" alt="BestReply"
                         class="mx-auto h-[60px] mb-10"/>
                </a>

                <div v-if="page.props.auth" class="absolute top-4 right-4">
                    <div @click="toggleDropdown"
                         class="flex items-center cursor-pointer space-x-2 border border-gray-200 dark:border-slate-600 px-2 rounded-md">
                        <UserCircleIcon class="size-10" />
                        <UserInfo :user="page.props.auth"/>
                    </div>

                    <div v-show="isDropdownOpen"
                         class="absolute right-0 mt-2 w-48 bg-white dark:bg-slate-800 border dark:border-slate-600 shadow-lg rounded-md overflow-hidden z-10">
                        <ul>
                            <li v-for="(item, index) in dropdownItems" :key="index"
                                class="px-4 py-2 hover:bg-gray-100 dark:hover:bg-slate-600 cursor-pointer">
                                <Link :href="item.route" v-if="item.route" :method="item.method" as="button">
                                    <component :is="item.icon" class="h-6 w-6 inline-block mr-2"/>
                                    {{ item.label }}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <component
                    :is="isDarkMode ? MoonIcon : SunIcon"
                    class="h-6 w-6 text-gray-400 absolute top-4 left-4 cursor-pointer hover:text-purple-500"
                    @click="ThemeStore.toggle()"
                />

                <PageTransition>
                    <div :key="$page.url">
                        <slot/>
                    </div>
                </PageTransition>
            </div>
        </div>
    </div>
</template>
